
import styled from "styled-components";
import WrapperPage from "../components/wrapper-page";
import Wrapper from "../components/wrapper";
import { FaBook, FaFileMedicalAlt, FaLink, FaList, FaRegCalendarAlt } from "react-icons/fa";
import { HiDownload, HiOfficeBuilding } from "react-icons/hi";
import { MdOutlinePayment, MdPolicy } from "react-icons/md";
import { FaFileCircleCheck, FaUserGroup } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { RiArrowDownSLine, RiArrowUpSLine, RiShakeHandsLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { ROUTES } from "../constants/routes";
import { ICalendar } from "../types/StudentFamily";
import { StudentsFamilyApi } from "../api/students-family";
import { IBooks } from "../types/Books";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media (max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    font-size: 38px;
    font-weight: bold;

    @media(max-width: 900px){
        font-size: 24px;
        margin-bottom: 0;
    }
`;

const H3 = styled.h3`
    font-size: 24px;
    font-weight: bold;
    padding: 48px 0 12px;

    @media(max-width: 900px){
        font-size: 21px;
        margin-bottom: 0;
    }

    @media (max-width: 600px){
        padding: 24px 0 12px;
    }
`;

const Sections = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width: 900px){
        flex-direction: column;
        gap: 16px;
    }
`;

const SectionLeft = styled.div`
    display: flex; 
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @media (max-width: 900px){
        gap: 16px;
    }
`;

const SectionRight = styled.div`
    display: flex; 
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @media (max-width: 900px){
        gap: 16px;
    }
`;

const Section = styled.div<{ clicable?: boolean }>`
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    border-radius: 8px;
    height: max-content;
    cursor: ${props => props.clicable ? 'pointer' : 'initial'};

    @media (max-width: 900px) {
        flex: initial;
        width: 100%;
        max-width: initial;
    }

    @media (max-width: 600px) {
        padding: 16px;
        gap: 16px;
    }
`;

const Title = styled.div`
    display: flex; 
    justify-content: space-between;
    align-items: center;

    > svg{
        border: 2px solid #343a40;
        border-radius: 50%;
        padding: 4px;
        box-sizing: initial;
        cursor: pointer;
        min-width: 14px;
        height: 14px;
    }
`;

const TitleText = styled.div`
    font-size: 21px;
    font-weight: bold; 
    line-height: 24px;

    @media (max-width: 600px){
        font-size: 18px;
    }
`;


const Description = styled.div<{ expanded: boolean }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${props => (props.expanded ? 'none' : '2')};
    line-clamp: ${props => (props.expanded ? 'none' : '2')};
    transition: -webkit-line-clamp 0.3s ease;
`;

const DescriptionWrapper = styled.div`
    display: flex;
`;

const List = styled.div`
    display: flex; 
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    padding: 12px 0;
`;

const Item = styled.div`
    padding: 8px;
    background-color: #c82d2d;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    width: 80px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    border-radius: 50%;
    padding: 14px;
    width: 54px;
    height: 54px;
    background-color: #c82d2d;

    > svg {
        width: 42px;
        height: 42px;
        fill: #fff;
    }

    @media (max-width: 600px){
        width: 42px;
        height: 42px;
        padding: 12px;

        > svg {
            width: 30px;
            height: 30px;
        }
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

const Ieduca = styled.img`
    width: 54px;
    height: 54px;
    border-radius: 50%;

    @media (max-width: 600px){
        width: 42px;
        height: 42px;
    }
`;

const Expand = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
`;

const ButtonBooks = styled.div`
    padding: 8px 12px;
    background-color: #c82d2d; 
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
`;

const BooksWrapper = styled.div`
    display: flex; 
    gap: 12px;
`;


function Students() {
    const [isExpanded, setIsExpanded] = useState<number>(0);
    const [selectedBook, setSelectedBook] = useState<string>('');
    const [calendar, setCalendar] = useState<ICalendar | null>(null);
    const [books, setBooks] = useState<IBooks | null>(null);

    useEffect(() => {
        StudentsFamilyApi.ListCalendar()
            .then((x: ICalendar) => { setCalendar(x) })
            .catch(x => console.log(x));
        StudentsFamilyApi.ListBooks()
            .then((x: IBooks) => { setBooks(x) })
            .catch(x => console.log(x));
    }, []);

    const toggleExpand = (event: any, id: number) => {
        event.stopPropagation();
        if (id === isExpanded) setIsExpanded(0);
        if (id !== isExpanded) setIsExpanded(id);
    }

    const openUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const openBooks = () => {
        if (!selectedBook) return;
        openUrl(selectedBook);
    }

    return (
        <Content>
            <Helmet>
                <title>Alumnat i famílies</title>
                <meta name="description" content="Alumnat i famílies" />
            </Helmet>
            <WrapperPage>
                <Wrapper>
                    <H1>Alumnat i famílies</H1>

                    <H3>Informació d’interés</H3>
                    <Sections>
                        <SectionLeft>
                            <Section  >
                                <IconWrapper>
                                    <FaRegCalendarAlt />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Calendari escolar</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl(calendar?.url!)} />
                                    </Title>
                                    <Description expanded={isExpanded === 1}>Consulta el calendari del curs actual amb la informació de dates importants com l'inici de curs, finals de trimestres, festius o dies no lectius.</Description>
                                </InfoWrapper>
                            </Section>
                            <Section>
                                <Ieduca src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fieduca.png?alt=media&token=baa4678c-ceb0-4048-ada0-c71b64da028f'} alt="ieduca" />
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Comunicació interna</TitleText>
                                        <FaLink color='#343a40' size="1em" onClick={() => openUrl('https://emt.ieduca.com')} />
                                    </Title>
                                    <Description expanded={isExpanded === 2}>La comunicació alumnat-escola és a través de la plataforma iEduca</Description>
                                </InfoWrapper>
                            </Section>
                        </SectionLeft>
                        <SectionRight>
                            <Section >
                                <IconWrapper>
                                    <HiOfficeBuilding />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Tràmits a secretaria</TitleText>
                                        <FaLink color='#343a40' size="1em" onClick={() => openUrl(window.location.origin + ROUTES.Tramits)} />
                                    </Title>
                                    <Description expanded={isExpanded === 4}>Consulta els tràmits que es poden fer a la secretaria del centre en funció dels estudis i els certificats que es poden sol·licitar. </Description>
                                </InfoWrapper>
                            </Section>
                            <Section clicable={false}>
                                <IconWrapper>
                                    <FaBook />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title><TitleText>Llibres de text</TitleText></Title>
                                    <Description expanded={isExpanded === 3}>Consulta la llista de llibres de text</Description>
                                    <BooksWrapper>
                                        <Form.Select onChange={(e) => setSelectedBook(e.target.value)}>
                                            <option value={''}>Seleccionar</option>
                                            <option value={books?.ESO1!}>1r ESO</option>
                                            <option value={books?.ESO2!}>2n ESO</option>
                                            <option value={books?.ESO3!}>3r ESO</option>
                                            <option value={books?.ESO4!}>4t ESO</option>
                                            <option value={books?.Batxillerat1!}>1r Batxillerat</option>
                                            <option value={books?.Batxillerat2!}>2n Batxillerat</option>
                                            <option value={books?.Administratiu!}>Administratiu</option>
                                            <option value={books?.Automocio!}>Automoció</option>
                                            <option value={books?.Electricitat!}>Electricitat i electronica</option>
                                            <option value={books?.Mecanica!}>Mecànica</option>
                                            <option value={books?.Quimica!}>Química</option>
                                            <option value={books?.Sanitaria!}>Sanitat</option>
                                        </Form.Select>
                                        <ButtonBooks onClick={() => openBooks()}>Obrir</ButtonBooks>
                                    </BooksWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionRight>
                    </Sections>

                    <H3>Informació útil per a l’alumnat del centre durant el curs</H3>
                    <Sections>
                        <SectionLeft>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <FaUserGroup />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Acta de reunió de grup</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/12IRGj1PunQtxuXSTMbFntXzeagEb7rEQ/view?usp=drive_link')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 5}>Si durant el curs escolar el grup classe realitza alguna reunió de grup amb algun interés concret, s’haurà d’entregar l’Acta de reunió de grup a l’equip directiu.
                                            Possibles temes tractats:
                                            elegir els representants dels alumnes del present curs
                                            adhesió a una convocatòria de vaga
                                            acords diversos
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 5)}>
                                            {isExpanded === 5 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                            <Section clicable={true}>
                                <IconWrapper>
                                    <FaList />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Protocol de vaga</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/1HlVHeS9UmB3AFPPI4EQX_m_K1cUaH_-4/view?usp=drive_link')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 6}>Les decisions col·lectives adoptades per l’alumnat en relació amb la seva no assistència a classe, quan aquesta decisió sigui el resultat de l’exercici del dret de reunió (vaga), ha d’estar prèviament comunicada a la direcció del centre per tal que no tinguin la consideració de falta ni sigui objecte de sanció.
                                            En cas de decidir realitzar la vaga, el grup classe ha de presentar la documentació de petició de vaga als caps d’estudis 48 hores abans de la realització de la mateixa.
                                            En el cas de ser menor d’edat, s’ha de disposar de la corresponent autorització dels pares, que haurà de ser entregada al tutor/a 48 hores abans de la realització de la vaga.
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 6)}>
                                            {isExpanded === 6 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionLeft>
                        <SectionRight>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <FaFileCircleCheck />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Retard justificat per alumnat de Postobligatori</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/1Ah7abSYfUtRA1F2WQB21MrsH-F9RyvcV/view?usp=drive_link')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 7}>
                                            En el cas d’alumnat de Postobligatori que requereixi sol·licitar l’entrada al centre amb retard causat per l’horari de transport o horari de la feina, s’ha d’emplenar el document de sol·licitud d’autorització i s’ha d’entregar a la direcció.
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 7)}>
                                            {isExpanded === 7 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionRight>
                    </Sections>

                    <H3>Informació útil per a les famílies del centre</H3>
                    <Sections>
                        <SectionLeft>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <Ieduca src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fieduca.png?alt=media&token=baa4678c-ceb0-4048-ada0-c71b64da028f'} alt="ieduca" />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Comunicació</TitleText>
                                        <FaLink color='#343a40' size="1em" onClick={() => openUrl('https://emt.ieduca.com')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 8}>Les famílies (d’alumnes menors d’edat) també tindran com a principal comunicació la plataforma iEduca i més directa a través del tutor/a del seu fill/a.</Description>
                                        <Expand onClick={(e) => toggleExpand(e, 8)}>
                                            {isExpanded === 8 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <MdOutlinePayment />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Pagaments durant el curs escolar</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/1OxdgQUhvwuQzvp54X5f0Rm_N1LkQk5VQ/view?usp=sharing')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 9}>
                                            L'alumnat (o famílies) que ja estigui matriculat al centre haurà de fer els pagaments mitjançant l'aplicació Tokapp. Podeu consultar aquí les instruccions de Tokapp.
                                            L'ingrés mitjançant transferència serà excepcional i sempre prèvia autorització del centre. En cas de no poder fer el pagament, contacteu amb les oficines del centre.
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 9)}>
                                            {isExpanded === 9 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                            <Section clicable={false} >
                                <IconWrapper>
                                    <IoMdMail />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Carta de compromís educatiu</TitleText>
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 10}>
                                            Conscients que l’educació dels joves implica l’acció conjunta de la família/alumnat i del centre educatiu, signem les següents cartes de compromís educatiu:Consulta la Carta de compromís educatiu
                                            <List>
                                                <Item onClick={() => openUrl('https://drive.google.com/file/d/1YP_WS6cheEBpa-ZcIhaB-ljjPw-aFSYN/view?usp=drive_link')}>ESO</Item>
                                                <Item onClick={() => openUrl('https://drive.google.com/file/d/1aiL6f6rhFNwFoXkZPBTiDD7EMSs9TiKU/view?usp=drive_link')}>Batxillerat</Item>
                                                <Item onClick={() => openUrl('https://drive.google.com/file/d/18RJKG_EUrX4PZl_20-hmb0jqoutmKoqA/view?usp=drive_link')}>Cicles formatius</Item>
                                            </List>
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 10)}>
                                            {isExpanded === 10 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionLeft>
                        <SectionRight>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <FaFileMedicalAlt />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Assegurança escolar obligatòria</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/1kO_wYagj5252-HCPaIp86Z8erE8VnUuM/view?usp=drive_link')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 11}>Té per finalitat la protecció de l’estudiant contra circumstàncies adverses, fortuïtes i previsibles</Description>
                                        <Expand onClick={(e) => toggleExpand(e, 11)}>
                                            {isExpanded === 11 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                            <Section clicable={true} >
                                <IconWrapper>
                                    <MdPolicy />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Política de protecció de dades</TitleText>
                                        <HiDownload color='#343a40' size="1em" onClick={() => openUrl('https://drive.google.com/file/d/1ftnf9WK9Xw_g8vOg4XMKrOjN95lCC1Iq/view?usp=drive_link')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 12}>Als centres educatius protegim les dades perquè es respectin els drets.</Description>
                                        <Expand onClick={(e) => toggleExpand(e, 12)}>
                                            {isExpanded === 12 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionRight>
                    </Sections>

                    <H3>Consell Escolar</H3>
                    <Sections>
                        <SectionLeft>
                            <Section clicable={true}>
                                <IconWrapper>
                                    <RiShakeHandsLine />
                                </IconWrapper>
                                <InfoWrapper>
                                    <Title>
                                        <TitleText>Consell Escolar</TitleText>
                                        <FaLink color='#343a40' size="1em" onClick={() => openUrl('https://educacio.gencat.cat/ca/arees-actuacio/centres-serveis-educatius/centres/consells-escolars-centres/')} />
                                    </Title>
                                    <DescriptionWrapper>
                                        <Description expanded={isExpanded === 13}>
                                            El consell escolar del centre és l’òrgan col·legiat de participació de la comunitat escolar en el govern del centre. Hi ha representats tots els col·lectius que intervenen en la comunitat educativa: director o directora, professorat, alumnes, personal d’administració i serveis, etc.
                                            Els membres del consell escolar són elegits per un període de quatre anys i es renoven per meitats cada dos anys.
                                            Més informació a educacio.gencat.cat.
                                        </Description>
                                        <Expand onClick={(e) => toggleExpand(e, 13)}>
                                            {isExpanded === 13 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                        </Expand>
                                    </DescriptionWrapper>
                                </InfoWrapper>
                            </Section>
                        </SectionLeft>
                        <SectionRight />
                    </Sections>
                </Wrapper>
            </WrapperPage>
        </Content >
    );
}

export default Students;