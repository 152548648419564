
import styled from "styled-components";
import { FiMail, FiPhone, FiClock, FiInstagram, FiFacebook } from 'react-icons/fi';
import Wrapper from "./wrapper";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";
import { ROUTES } from "../constants/routes";
import theme from "../constants/theme";
import { Alert } from "react-bootstrap";


const FooterContent = styled.div`
  background-color: #dee2e6;
  color: #343a40;
`;

const WrapperFlex = styled.div`
  display:flex;
  gap: 30px;
  padding: 32px;

  @media(max-width: 930px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: 600px) {
    padding: 32px 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: start;

  @media(max-width: 900px){
    align-items: center;
  }
`;

const Icon = styled.div`
  width: 30px;
  > svg {
    stroke: #343a40 !important;
    
  }
`;

const Item = styled.div`
  color: #343a40;

  @media(max-width: 600px) {
    font-size: 14px;
  }
`;

const Module = styled.div<{ width: number }>`
  width: ${props => props.width}%;
  @media(max-width: 930px){
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #343a40;

  @media(max-width: 600px) {
    margin-bottom: 4px;
  }
`;

const SocialNetwork = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  gap: 14px;
  color: #343a40;
  cursor: pointer;
`;

const Copyright = styled.div`
  padding: 18px 0 0;
  font-size: 14px;
  text-align: center;

  @media (max-width: 930px){

    text-align: left;
  }

  @media(max-width: 600px) {
    padding: 0;
    font-size: 12px;
  }
`;

const Ods = styled.div`
  display: flex;
  align-items: center; 
  justify-content: start;
  gap: 12px;
  margin: 24px 0 0;
`;

const Od = styled.img`
  width: 50px;
  height: auto; 
`;

const Educat = styled.img`
  width: 90px;
  height: auto;
  cursor:pointer;
  margin-top: 24px;

`;

const Link = styled(Alert.Link)`
  color: ${theme.colors.link};
  font-size: 14px;
`;

const Links = styled.div`
  text-align: center;
  padding-bottom: 18px;

  @media(max-width: 600px) {
    text-align: left;
    font-size: 12px;
    padding-top: 12px;
  }
`;

const Link2 = styled.a`
    color: ${theme.colors.link};
    font-size: 14px;
`;

export default function Footer() {
  return (
    <FooterContent>
      <Wrapper>
        <WrapperFlex>
          <Module width={45}>
            <Title>Sobre nosaltres</Title>
            <Item>
              L’Institut Escola Municipal de Treball de Granollers reuneix a uns 1800 alumnes, 140 professors/es, estudis d’ESO, Batxillerat i Formació professional amb un projecte educatiu participatiu i innovador.
            </Item>
            <Ods>
              <Link2 target="_blank" href="https://cads.gencat.cat/ca/Agenda_2030/informe-cads-agenda-2030/ods-4/index.html">
                <Od src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fods%2F4.webp?alt=media&token=1dab0c8c-a324-46f9-aae3-8059c6ecca1f'} alt="ods4" />
              </Link2>
              <Link2 target="_blank" href="https://cads.gencat.cat/ca/Agenda_2030/informe-cads-agenda-2030/ods-5/index.html">
                <Od src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fods%2F5.webp?alt=media&token=8d33ad26-9c29-4ed4-bef2-5249b855ff3f'} alt="ods5" />
              </Link2>
              <Link2 target="_blank" href="https://cads.gencat.cat/ca/Agenda_2030/informe-cads-agenda-2030/ods-8/index.html">
                <Od src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fods%2F8.webp?alt=media&token=cde9f18a-ef00-4fc6-b3b2-77cfadf74b49'} alt="ods8" />
              </Link2>

            </Ods>
          </Module>
          <Module width={25}>
            <Title>Contacte</Title>
            <Row>
              <Icon><SiGooglemaps /></Icon>
              <Item>Roger de Flor, 66 <br />
                Granollers 08401, Barcelona</Item>
            </Row>
            <Row>
              <Icon><FiMail /></Icon>
              <Item> oficines@iesemt.net</Item>
            </Row>
            <Row>
              <Icon><FiPhone /></Icon>
              <Item>93 879 5518</Item>
            </Row>
            <Educat src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fieduca_text.png?alt=media&token=9988ce22-20a4-43d4-a2b1-575c428c0994'}
              onClick={() => window.open('https://emt.ieduca.com')}
              alt="ieduca" />
          </Module>
          <Module width={30}>
            <Title>Horari d'oficines</Title>
            <Row>
              <Icon><FiClock /></Icon>
              <Item>Dilluns a divendres: 10h a 12h</Item>
            </Row>
            <Row>
              <Icon><FiClock /></Icon>
              <Item>Dilluns a dijous: 14:30h a 16:30h</Item>
            </Row>
            <Row>
              <SocialNetwork>
                <FaSquareXTwitter onClick={() => window.open('https://x.com/institutemt?s=21&t=J2vGU5xb_7zu3dx74ZCS9g')} size="2em" />
                <FiInstagram onClick={() => window.open('https://www.instagram.com/institutemt?igsh=MWJuc3cwMTk2cXh5eA==')} size="2em" />
                <FiFacebook onClick={() => window.open('https://www.facebook.com/institutemt')} size="2em" />
              </SocialNetwork>
            </Row>
          </Module>
        </WrapperFlex>
        <Copyright>Copyright © 2019-2022 Institut Escola Municipal de Treball de Granollers. Tots els drets reservats.</Copyright>
        <Links>
          <Link href={ROUTES.PolicyPrivacy} target='_blank'>Política de privacitat</Link>{' '}
          <Link href={ROUTES.PolicyCookies} target='_blank'>Política de cookies</Link>
        </Links>
      </Wrapper>
    </FooterContent>
  );
}
