
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { Helmet } from 'react-helmet';

const Content = styled.div`
`;

const Text = styled.div`
    padding: 24px 0;

    @media (max-width: 900px){
        width: 100%;
    }
`;

const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

/*const SubTitulo = styled.p`
    text-align: center;
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 18px;
    }
`;*/

const Paragraf = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const ImageRight = styled.img`
    float: right;
    height: auto;
    margin: 0 0 32px 32px;
    border-radius: 8px;
    width: 140px;
    height: 240px;

    @media(max-width: 900px){
        margin: 0 0 24px 24px;
    }

    @media(max-width: 600px){
        width: 100px;
        height: auto;
        margin: 0 0 12px 12px;
    }
`;

const Title = styled.div`
    font-weight: bold; 
    display: block;
`;

const PaddingLeft = styled.div`
    padding-left: 24px;

    @media(max-width: 600px){
        padding-left: 12px;
    }
`

const Download = styled.a``;


function PoliticaQualitat() {
    return (
        <Content >
            <Helmet>
                <title>Política de qualitat</title>
                <meta name="description" content="Política de qualitat" />
            </Helmet>
            <Wrapper padding="60px 30px 60px">
                <Titulo>Política de qualitat</Titulo>
                {/*<SubTitulo>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</SubTitulo>*/}
                <Text>
                    <ImageRight src={'https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/web%2Fapplus.png?alt=media&token=915d1840-b6cd-4880-831e-4b3894fcf022'} />
                    <Paragraf>
                        L’Institut EMT promou com a eina bàsica de millora, el manteniment i la millora contínua; la norma de referència és <b>UNE-EN-ISO-9001/2015</b>, per la qual cosa el seu funcionament ha d’estar d’acord amb la normativa vigent i els requisits establerts per l’administració educativa.
                        Amb aquest model de gestió estratègica basat en la Qualitat i Millora Contínua, d’acord amb els seus paràmetres, <b>planificar, implementar, avaluar i millorar</b>, volem aconseguir:
                    </Paragraf>
                    <Paragraf>
                        <PaddingLeft>
                            a) L’augment de la capacitat del nostre Institut per donar resposta a les necessitats educatives dels alumnes, famílies i empreses. <br />
                            b) La millora contínua de l’acció educativa docent, la qual cosa implica les quatre fases de gestió de qualitat (planificar, implementar, avaluar i millorar) a partir d’unes programacions didàctiques reals i amb un seguiment acurat d’aquestes.<br />
                            c) Millorar la satisfacció de l’equip humà del centre (Professorat, PAS, alumnat, famílies, etc.)<br />
                            d) Millorar els resultats acadèmics del centre.<br />
                        </PaddingLeft>
                    </Paragraf>
                    <Paragraf>
                        El compromís de la Qualitat afecta a tota l’organització del centre, a tota la comunitat educativa i empreses col·laboradores, que accepten aquest repte i assumeixen la responsabilitat de participar activament en el desenvolupament i millora de la qualitat en el centre. La direcció del centre vetllarà i proporcionarà els mitjans que estiguin al seu abast per assolir els objectius de qualitat.
                    </Paragraf>
                    <Paragraf>
                        <Title>Missió</Title>
                        L’EMT té com a missió formar persones creatives, autònomes i responsables. Així com a professionals en els camps de l’administració, la mecànica, l’electricitat-electrònica, la sanitat, la química i l’automoció.
                    </Paragraf>
                    <Paragraf>
                        <Title>Visió</Title>
                        L’EMT vol ser un centre de secundària que vetlli permanentment per la millora de la qualitat dels seus serveis educatius satisfent en matèria educativa els interessos i necessitats de la ciutadania de Granollers i un centre de FP de qualitat i referència de tot el Vallès Oriental.
                    </Paragraf>
                    <Paragraf>
                        <Title>Valors</Title>
                        El centre ha de servir d’orientació i ajuda als joves de cara a l’aprenentatge de la convivència i la solidaritat.
                        El centre entén la coeducació com el procés d’educar per a la igualtat sense cap mena de discriminació per raó de gènere.
                        S’orienta la vida del centre amb els valors bàsics de la societat democràtica: tolerància, llibertat, responsabilitat i diàleg.
                    </Paragraf>
                    <Paragraf>
                        <Download
                            target='_blank'
                            href='https://drive.google.com/file/d/1qae-LxWGGGebdE2b4cfxuEZlFe3L6Y6S/view?usp=drive_link'>
                            Podeu consultar o descarregar el certificat actual aquí
                        </Download>
                    </Paragraf>
                </Text>
            </Wrapper>
        </Content >
    );
}

export default PoliticaQualitat;